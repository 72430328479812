import React, {useState, useEffect} from 'react';
import Input from '../../../components/input';
import TextArea from '../../../components/text-area';
import Async from 'react-select/async';
import {searchVisitant} from '../../../redux/visits';
import {useDispatch, useSelector} from "react-redux";
import ItemChat from "../../../components/item-chat";
import {URLAPI} from "../../../config";
import Button from "../../../components/button";
import {createVisit} from '../../../redux/visits';
import {toast, ToastContainer} from 'react-toastify';
import LoadingSpinner from "../../../components/loading-spinner";

const CreateVisitModal = ({open}) => {
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    let dispatch = useDispatch();
    const _create_visit = useSelector
    ((state) => state.visits_module._create_visit);
    const [selectOptions, setSelectOptions] = useState([])

    const [cost_center_select, setCostCenterSelect] = useState(0)
    const [fecha, setFecha] = useState("")

    const [openS, setOpenS] = useState(open)

    const init = {
        titulo: "",
        descripcion: "",
        observacion: "",
        id_centro_costo: 0,
        visita_programada: false,
        fecha: "",
        visitantes: [],

    };

    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState(init)
    const [visitantsSelected, setVisitantSelected] = useState({
        visitantes: [],
        empleados: [],
        proveedores: [],
    });
    const _session_data = useSelector((state) => state.users_module._session_data);


    useEffect(() => {
        if (_create_visit.status === 201) {
            setForm(init)
            setVisitantSelected({
                visitantes: [],
                empleados: [],
                proveedores: [],
            })
            setSelectOptions([])
            handleClose();
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_create_visit, init])

    useEffect(() => {
        let div = null;
        if (openS) {
            div = document.createElement("div")
            div.classList = "modal-backdrop fade show "
            div.id = "backmodal"
            document.body.appendChild(div)

        }
    }, [openS])

    useEffect(() => {
        setOpenS(open)
    }, [open])


    useEffect(() => {
        if (_session_data.data.centros_costos !== undefined) {
            if (_session_data.data.centros_costos.length !== 0) {
                let date = new Date();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let year = date.getFullYear();
                if (day < 10) {
                    day = "0" + day;
                }
                if (month < 10) {
                    month = "0" + month;
                }
                setFecha(year + "-" + month + "-" + day)
                setCostCenterSelect(_session_data.data.centros_costos[0].id)

            }
        }
    }, [_session_data])


    const handleClose = () => {
        setOpenS(!openS)
        let div = document.getElementById("backmodal")
        if (div != null) {
            div.remove();
        }
    }

    const handleChangeSelect = (e) => {
        console.log(_session_data.data)
        if (_session_data.data.omitir_restriccion_visita === true){
            setSelectOptions(e);
        } else {
            if (e[0] && e[0].item.visita_no_programada === 0) {
                toast.error("No se puede agregar el " + e[0].type +" para ese tipo de visita");
                setSelectOptions([...selectOptions]);
                return;
            } else {
                setSelectOptions(e);
            }
        }
        // setSelectOptions(e);
        let empleados = [];
        let visitantes = [];
        let proveedores = [];

        e.forEach(element => {
            if (element.type === "proveedor") {
                proveedores.push(element.item);
            }

            if (element.type === "empleado") {
                empleados.push(element.item);
            }

            if (element.type === "visitante") {
                visitantes.push(element.item);
            }
        });
        let com = [];
        if (empleados.length !== 0) {
            com = empleados.filter(x => x.visita_sin_aprobacion === true)
        }

        if (com.length !== 0) {
            if (proveedores.length !== 0) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                proveedores = []
            }
            if (visitantes.length !== 0) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                visitantes = []
            }
            if (com.length !== empleados.length) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                empleados = com;
            }
        }
        setVisitantSelected({
            visitantes: visitantes,
            empleados: empleados,
            proveedores: proveedores
        });
    }

    const promiseOptions = (inputValue) => {

        if (inputValue.length === 0) {
            return Promise.resolve({options: []});
        }
        //evaluar si es un numero
        let param = "?"
        if (inputValue.match(/^[0-9]+$/)) {
            param += "rut=" + inputValue
        }

        //evaluar si son letras
        if (inputValue.match(/^[a-zA-Z\s]+$/)) {
            param += "nombres=" + inputValue
        }
        // param += "&visita_no_programada=true"


        return new Promise((resolve, reject) => {
            setTimeout(() => {
                searchVisitant(token, param).then((res) => {
                    let datos = []

                    res.data.forEach(element => {
                        datos.push({
                            value: element.id,
                            label: element.nombres,
                            type: element.tipo,
                            item: element
                        })
                    });
                    resolve(datos);

                }).catch((err) => {
                    reject(err);
                })
            }, 1000);
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        form.visitantes = [];
        if (visitantsSelected.empleados.length === 0 && visitantsSelected.visitantes.length === 0 && visitantsSelected.proveedores.length === 0) {
            toast.error("Debe seleccionar al menos un visitante");
            setIsLoading(false);
            handleClose();
            return;
        }
        visitantsSelected.empleados.forEach(element => {
            form.visitantes.push({
                tipo: "EMPLEADO",
                rut: element.rut
            });
        })

        visitantsSelected.visitantes.forEach(element => {
            form.visitantes.push({
                tipo: "VISITANTE",
                rut: element.rut
            });

        })

        visitantsSelected.proveedores.forEach(element => {
            form.visitantes.push({
                tipo: "PROVEEDOR",
                rut: element.rut
            });
        })

        form.id_centro_costo = cost_center_select;
        form.fecha = [{'fecha': fecha}];

        dispatch(createVisit(token, form));
        setForm(init)

    }

    return (
        <div className={`modal fade text-left ${openS ? 'show' : ''}`} id="inlineForm" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel33" aria-hidden={openS ? 'true' : 'false'}
             style={{
                 display: openS ? 'block' : ''
             }}
        >
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <ToastContainer/>
                    {isLoading && <LoadingSpinner/>}
                    <div className="modal-header">
                        <h4 className="text-bold-400">Nueva visita no programada </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => handleClose()}>
                            <i className="bx bx-x"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} style={{overflowY: 'scroll'}}>
                        <div className="modal-body container">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Título"
                                        name="titulo"
                                        type="text"
                                        value={form.titulo}
                                        onChange={handleChange}
                                        placeholder="Título"
                                        required={true}
                                    />
                                    <TextArea
                                        label="Motivo de la visita"
                                        name="descripcion"
                                        id="descripcion"
                                        onChange={handleChange}
                                        value={form.descripcion}
                                        rows={5}
                                        placeholder="Motivo de la visita"
                                    />
                                    <TextArea
                                        label="Observación"
                                        name="observacion"
                                        id="observacion"
                                        onChange={handleChange}
                                        value={form.observacion}
                                        rows={5}
                                        placeholder="Observación"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Visitantes</label>
                                        <Async
                                            value={selectOptions}
                                            isMulti={true}
                                            defaultOptions={false}
                                            loadOptions={promiseOptions}
                                            onChange={(e) => {
                                                handleChangeSelect(e)
                                            }}
                                        />
                                    </div>

                                    <ul className="chat-sidebar-list">
                                        {
                                            visitantsSelected.empleados.map((item, index) => {
                                                return (
                                                    <ItemChat title={"EMPLEADO"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              }
                                                              key={index}/>
                                                )
                                            })
                                        }
                                        {
                                            visitantsSelected.visitantes.map((item, index) => {
                                                return (
                                                    <ItemChat title={"VISITANTE"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              }
                                                              key={index}/>
                                                )
                                            })
                                        }
                                        {
                                            visitantsSelected.proveedores.map((item, index) => {
                                                return (
                                                    <ItemChat title={"PROVEEDOR"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              } key={index}/>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer">
                            <Button type="submit" text="Guardar" theme="success" onClick={() => {
                            }}>Cerrar</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateVisitModal;
